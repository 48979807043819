import React from 'react'
import { StyledBottomContainer, StyledTitle, StyledImageGalleryContainer, StyledButtonsContainer, StyledLeftContainer, StyledRightContainer, StyledImage, StyledImageContainer } from './styles';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';
import WrImage from '@commons/wr-image';
import WrButton from '@commons/wr-button';

export default function MasonryVertical({
    module,
}) {
    return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            isOverflowHidden={true}
            data-name="gallery_masonry-vertical"
        >
            <StyledContainer>  
                {module.title && 
                    <StyledTitle
                        fontSize={{ mobile: 'lg', desktop: 'md' }}
                    > 
                        {module.title}
                    </StyledTitle>
                }
                <StyledImageGalleryContainer>
                    <StyledLeftContainer>
                        {module.leftImages.map(({ image }, i) => (
                            <StyledImageContainer key={image.altText + i + 'gallery-masonry-vertical-left'}>
                                <WrImage
                                    imageFile={image.imageFile}
                                    alt={image.altText}
                                />
                            </StyledImageContainer>     
                        ))}
                    </StyledLeftContainer>

                    <StyledRightContainer>
                        {module.rightImages.map(({ image }, i) => (
                           <StyledImageContainer key={image.altText + i + 'gallery-masonry-vertical-right'}>
                                <WrImage
                                    imageFile={image.imageFile}
                                    alt={image.altText}
                                />
                            </StyledImageContainer>  
                        ))}
                    </StyledRightContainer>

                    {module.bottomImages && 
                        <StyledBottomContainer>
                            {module.bottomImages.map(({ image }, i) => (
                            <StyledImageContainer 
                                key={image.altText + i + 'gallery-masonry-vertical-bottom'}
                                className="bottom-image-container"
                            >
                                    <WrImage
                                        imageFile={image.imageFile}
                                        alt={image.altText}
                                    />
                                </StyledImageContainer>  
                            ))}
                        </StyledBottomContainer>
                    }
                </StyledImageGalleryContainer>

                {module.buttonLink1 || module.buttonLink2 ? 
                    <StyledButtonsContainer>
                        {module.buttonLink1 && 
                            <WrButton 
                                href={module.buttonLink1.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"
                                buttonWrapperClass="masonry-vertical-button"
                            >
                                {module.buttonLink1.title}
                            </WrButton>
                        }

                        {module.buttonLink2 && 
                            <WrButton 
                                href={module.buttonLink2.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"
                                buttonWrapperClass="masonry-vertical-button"
                            >
                                {module.buttonLink2.title}
                            </WrButton>
                        }
                    </StyledButtonsContainer>
                : null}
        

            </StyledContainer>       
        </StyledModuleContainer>
    )
}


