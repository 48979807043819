import styled, { css } from 'styled-components';
import { theme } from '@theme';

import ModuleTitle from '@commons/module-title';

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 900;
    margin: 0 0 10px 0;
    width: 100%;
    text-align: center;

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 15px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 20px;
    }
`

export const StyledImageGalleryContainer = styled.div`
    position: relative;
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px 0;
    margin-bottom: 10px;

    ${theme.mediaQuerys.smUp} {
        max-width: 700px;
        margin-bottom: 15px;
    }

    ${theme.mediaQuerys.mdUp} {
        max-width: 900px;
        margin-bottom: 20px;
    }
`


export const StyledLeftContainer = styled.div`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
         display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
    }
`

export const StyledRightContainer = styled.div`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
    }
`

export const StyledBottomContainer = styled.div`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bottom-image-container {

        ${theme.mediaQuerys.smUp} {
            width: 80%;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 75%;
        }
    }
`

export const StyledImageContainer = styled.div`
    padding: 15px;

    ${theme.mediaQuerys.smUp} {
        padding: 20px;
    }

    > div { // gatsby image wrapper
        box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, .25);
    }
    
`

export const StyledButtonsContainer = styled.div`

    ${theme.mediaQuerys.mdUp} {
        display: flex;
    }

    .masonry-vertical-button {
        margin-bottom: 15px;

        ${theme.mediaQuerys.mdUp} {
            margin-right: 20px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
` 